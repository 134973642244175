.recording-operation-button {
  font-size: 30px;
  color: #fff;
  margin-left: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px !important;
  height: 60px !important;
  &.started-share{
    color: #00cc64;
  }
  > *{
    font-size: 28px !important;
  }
}